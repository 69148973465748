@import "App.css";

.staticLeft {
    background-color: var(--dark-blue);
    left: 0;
    width: 35%;
    color: var(--white);
    overflow: hidden;
}

.nicole {
    padding-right: 0.5em;
    color: var(--grey-white);
}

.streltsov {
    color: var(--light-blue);
}

.uoft-logo {
    max-width: 100%;
    margin-right: 0.2em;
}

.school {
    margin-top: 2em;
    display: flex;
    align-items: center;
}

.school p {
    height: 100%;
}

.school a {
    width: 18%;
}

.sketch {
    max-width: 70%;
}

.skills {
    margin: 2em auto;
    display: flex;  
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.tab {
    background-color: var(--light-blue);
    border-radius: 1em;
    display: inline;
    padding: 0.5em 1em;
    margin: 0.2em 0.6em;
    -webkit-animation: load 0.5s linear;
    animation: load 0.5s linear;
    opacity:0;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both;
    font-size: 0.8em;
 
}

.social-links img {
    width: 6%;
    color: white;
    margin: 0 0.5em;
}

.social-links .devpost, .envelope{
    width: 5.5%;  
}

.software-eng{
    margin-top: 0;
    font-size: 1.1em;
}

/* ----- media queries -----------*/
@media only screen and (max-width: 1150px){
    .tab {
        font-size: 0.7em;
    }
    .school p {
        margin-left: 0.25em;
    }
    .skills {
        margin-top: 1.9em;
    }
}

@media only screen and (max-width: 767px){
    .staticLeft {
        width: 100%;
    }
    .school p {
       font-size: 0.9em;
       margin-left: 0.5em;
    }
    .skills {
        margin: 2em 3em;
        margin-top: 3em;
    }
}