@import "App.css";

@keyframes slide {
    0% {margin-left: 60em;}
    100% {margin-left: 0;}
}

@-webkit-keyframes slide {
    0% {margin-left: 60em;}
    100% {margin-left: 0;}
}

@keyframes no-slide {
    0% {margin-left: 0;}
    100% {margin-left: 0;}
}

@-webkit-keyframes no-slide {
    0% {margin-left: 0;}
    100% {margin-left: 0;}
}


.dynamicRight {
    right: 0;
    width: 65%;
    overflow-y:auto;
}

.container {
    margin: 2em;
}

.expblock{
    border-radius: 1em;
    padding: 0.5em 1em;
    margin-bottom: 2em;
    width: 94.5%;
    margin-left: 60em;
    -webkit-animation: slide 1.5s forwards;
    animation: slide 1.5s forwards;
    box-shadow: 0 0px 0px 0 var(--dark-blue), 0 2px 3px 0 var(--dark-blue);
}

.expblock-container{
    margin: 0.5em 0.1em;
    position: relative;
}

.expblock-container img {
    position: absolute;
    top: 0;
    right: 0; 
    width: 10%;
    max-width: 100%;
    height: auto;
}

.expblock h3 {
    margin: 0;
}

.experience {
    color: var(--dark-blue);
}

.projects{
    -webkit-animation: load 2s linear;
    animation: load 2s linear;
}

.proj-text {
    color: var(--light-blue);
}

.expblock p {
    margin: 0;
}

.expblock .description{
    margin-top: 1.5em;
}
ul {
    margin: 0;
}

.expblock .exp-dates{
    margin-bottom: 1em;
}

.proj-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.projblock {
    flex: 0 0 32%;
    position: relative;
}

.projblock h3{
    margin-bottom: 0;
}

.projblock-front{
    margin-left: 0;
    z-index: 100;
    box-shadow: 0 3px 8px 0 var(--light-light-blue), 0 4px 10px 0 var(--light-light-blue);
    height: 100%;
}

.projblock-text{
    margin: 1em;
}

.projblock-front:hover{
    opacity:0.7;
}

.projblock-front img {
    width: 100%;
}

.projblock-front p{
    margin:0;
}

.projblock-back{
    background-color: var(--white);
    position: relative;
    padding: 1em;
}

.projblock-container{
    display: flex;
}

.projblock-back p {
    font-size: 0.8em;
}

.projblock-side { 
    width: calc(100% * 0.45);
    padding-left: 1em;
}
.projblock-side .main-img {
    float: right; 
    width: 100%;
    margin-bottom: 1.5em;
}

.projblock-side a {
    display: block;
    color: var(--link-blue);
}

.projblock-side .link-img {
    width: 5%;
    margin-right: 0.4em;
    vertical-align:middle;
    display:inline-block;
}
.projblock-side p{
    vertical-align:middle;
    display:inline-block;
    margin: 0;
}

.projblock-content {
    width: calc(100% * 0.55);
}

.projblock-content p {
    margin: 0.1em;
}

.projblock-content .desc {
    margin-top: 2em;
    font-size: 0.7em;
}

.projblock-content h3 {
    font-family: 'Playfair Display SC', serif;
    color: var(--light-blue);
    margin-bottom: 0;
}

.projblock .projblock-unflipped{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -10000;
    transform: rotateY(-180deg);
}

.projblock .projblock-flipped{
    visibility: visible;
    z-index: 130;
    opacity: 1;
    top: 30%;
    left: 21%;
    margin:auto auto;
    transform-style: preserve-3d;
    transition: .5s linear;
    position: fixed;
    -webkit-transform : rotateY(0deg) scale(1.5); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform     : rotateY(0deg) scale(1.5); /* IE 9 */
    transform         : rotateY(0deg) scale(1.5); /* Firefox 16+, IE 10+, Opera */
    width: 50%;
    height: auto;
}

.made-width{
    margin-top: 1em;
}

.copyright{
    text-align: center;
    margin-top: 2em;
    color: var(--light-light-blue);
}

.close-div{
    margin-bottom: 1.5em;
}

.close-bttn{
    float: right;
}

.close-bttn:hover{
    opacity: 0.5;
    cursor: pointer;
}

.proj-text-mobile{
    display: none;
}
.proj-text-desktop{
    display: block;
}

/* ----- media queries -----------*/
@media only screen and (max-width: 1328px){
    .projblock {
        flex: 0 0 48%;
    }
}

@media only screen and (max-width: 1150px){
    .projblock-back p {
        font-size: 0.7em;
    }
    .projblock-content .desc {
        font-size: 0.5em;
    }
}

@media only screen and (max-width: 900px){
    .projblock-back p {
        font-size: 0.5em;
    }
    .projblock-content .desc {
        font-size: 8px;
    }
}

@media only screen and (max-width: 767px){
    .dynamicRight {
        width: 100%;
        background-color: var(--white);
        overflow-y:hidden;
    }
    .projblock-side .link-img {
        width: 10%;
    }
    .expblock{
        margin-left: 0;
        -webkit-animation: no-slide 1.5s forwards;
        animation: no-slide 1.5s forwards;
    }
    .proj-text-mobile{
        display: block;
    }
    .proj-text-desktop{
        display: none;
    }
}

@media only screen and (max-width: 463px){
    .projblock {
        flex: 0 0 100%;
    }
    .projblock-side .link-img {
        width: 12%;
    }
    .projblock .projblock-flipped{
        left: 21%;
        width: 50%;
    }
}